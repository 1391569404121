import "../Security/Security.scss";

import React from "react";

import Accordion from "../../components/Accordion/Accordion";
import Box from "../../components/Box/Box";
import DownloadList from "../../components/DownloadList/DownloadList";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import { ContentElements } from "../../interfaces/ContentElements";
import { DownloadLink } from "../../interfaces/downloadLink";
import CmsManager from "../../services/manager/CmsManager";
import StateManager from "../../services/manager/StateManager";

interface Props {
  location: any;
}

interface FaqItem {
  title: string | DownloadLink[];
  content: string | DownloadLink[];
}

interface State {
  loading: boolean;
  content?: ContentElements;
  faqGeneral?: ContentElements;
  faqGeneralItems: FaqItem[];
  faqRegistration?: ContentElements;
  faqRegistrationItems: FaqItem[];
  faqCashback?: ContentElements;
  faqCashbackItems: FaqItem[];
  faqTueroeffnungsservice?: ContentElements;
  faqTueroeffnungsserviceItems: FaqItem[];
  faqSchluesselfundservice?: ContentElements;
  faqSchluesselfundserviceItems: FaqItem[];
  faqKartenunddokumentenservice?: ContentElements;
  faqKartenunddokumentenserviceItems: FaqItem[];
  faq24hhotline?: ContentElements;
  faq24hhotlineItems: FaqItem[];
}

export default class DocumentsAndFormulars extends React.Component<
  Props,
  State
> {
  private params: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      faqGeneralItems: [],
      faqRegistrationItems: [],
      faqCashbackItems: [],
      faqTueroeffnungsserviceItems: [],
      faqSchluesselfundserviceItems: [],
      faqKartenunddokumentenserviceItems: [],
      faq24hhotlineItems: [],
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const contents = await Promise.all([
      CmsManager.getPageContentsCleaned("service-hilfe"),
      CmsManager.getPageContentsCleaned("faq-allgemein"),
      CmsManager.getPageContentsCleaned("faq-anmeldung"),
      CmsManager.getPageContentsCleaned("faq-cashback"),
      CmsManager.getPageContentsCleaned("faq-turoffnungsservice"),
      CmsManager.getPageContentsCleaned("faq-schlusselfundservice"),
      CmsManager.getPageContentsCleaned("faq-karten-und-dokumentenschutz"),
      CmsManager.getPageContentsCleaned("faq-24h-hotline"),
    ]);

    contents.forEach((page, index) => {
      if (index !== 0) {
        Object.keys(page).forEach((item: string) => {
          if (
            item !== "pageTitle" &&
            item !== "pageDescription" &&
            item.endsWith("Title") &&
            typeof item === "string"
          ) {
            switch (index) {
              case 1:
                const tempGeneralItems = this.state.faqGeneralItems;

                this.setState({
                  faqGeneralItems: [
                    ...tempGeneralItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 2:
                const tempRegistrationItems = this.state.faqRegistrationItems;

                this.setState({
                  faqRegistrationItems: [
                    ...tempRegistrationItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 3:
                const tempCashbackItems = this.state.faqCashbackItems;

                this.setState({
                  faqCashbackItems: [
                    ...tempCashbackItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 4:
                const tempTueroeffnungsserviceItems = this.state
                  .faqTueroeffnungsserviceItems;

                this.setState({
                  faqTueroeffnungsserviceItems: [
                    ...tempTueroeffnungsserviceItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 5:
                const tempSchluesselfundserviceItems = this.state
                  .faqSchluesselfundserviceItems;

                this.setState({
                  faqSchluesselfundserviceItems: [
                    ...tempSchluesselfundserviceItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 6:
                const tempKartenunddokumentenserviceItems = this.state
                  .faqKartenunddokumentenserviceItems;

                this.setState({
                  faqKartenunddokumentenserviceItems: [
                    ...tempKartenunddokumentenserviceItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
              case 7:
                const temp24hhotlineItems = this.state.faq24hhotlineItems;

                this.setState({
                  faq24hhotlineItems: [
                    ...temp24hhotlineItems,
                    {
                      title: page[item] as string,
                      content: page[item.slice(0, -5)] as string,
                    },
                  ],
                });
                break;
            }
          }

          return true;
        });
      }
    });

    this.setState({
      loading: false,
      content: contents[0],
      faqGeneral: contents[1],
      faqRegistration: contents[2],
      faqCashback: contents[3],
      faqTueroeffnungsservice: contents[4],
      faqSchluesselfundservice: contents[5],
      faqKartenunddokumentenservice: contents[6],
      faq24hhotline: contents[7],
    });
  }

  render() {
    return (
      <Layout>
        <div className="service-hilfe">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <div>
              <InnerLayout>
                <ImageSlider
                  images={[
                    {
                      src: "/assets/images/heroes/serviceundhilfe.jpg",
                      contentBox: {
                        headline: this.state.content.pageTitle as string,
                        content: this.state.content.pageDescription as string,
                      },
                    },
                  ]}
                />

                {this.state.content.downloadlinksTitle &&
                  this.state.content.downloadlinksLinks &&
                  this.state.content.downloadlinksLinks.length > 0 && (
                    <Box>
                      <Headline type="h2" color="secondary">
                        {this.state.content.downloadlinksTitle}
                      </Headline>

                      <DownloadList
                        links={
                          this.state.content
                            .downloadlinksLinks as DownloadLink[]
                        }
                      ></DownloadList>
                    </Box>
                  )}

                {this.state.faqGeneral &&
                  this.state.faqGeneralItems &&
                  this.state.faqGeneralItems.length > 0 && (
                    <Box>
                      <Headline type="h2" color="secondary">
                        {this.state.faqGeneral.pageTitle}
                      </Headline>
                      <Accordion items={this.state.faqGeneralItems}></Accordion>
                    </Box>
                  )}

                {this.state.faqRegistration &&
                  this.state.faqRegistrationItems &&
                  this.state.faqRegistrationItems.length > 0 && (
                    <Box>
                      <Headline type="h2" color="secondary">
                        {this.state.faqRegistration.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqRegistrationItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faqCashback &&
                  this.state.faqCashbackItems &&
                  this.state.faqCashbackItems.length > 0 && (
                    <Box>
                      <Headline type="h2" color="secondary">
                        {this.state.faqCashback.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqCashbackItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faqTueroeffnungsservice &&
                  this.state.faqTueroeffnungsserviceItems &&
                  this.state.faqTueroeffnungsserviceItems.length > 0 && (
                    <Box>
                      <Headline type="h2" color="secondary">
                        {this.state.faqTueroeffnungsservice.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqTueroeffnungsserviceItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faqSchluesselfundservice &&
                  this.state.faqSchluesselfundserviceItems &&
                  this.state.faqSchluesselfundserviceItems.length > 0 && (
                    <Box>
                      <Headline type="h2" color="secondary">
                        {this.state.faqSchluesselfundservice.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqSchluesselfundserviceItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faqKartenunddokumentenservice &&
                  this.state.faqKartenunddokumentenserviceItems &&
                  this.state.faqKartenunddokumentenserviceItems.length > 0 && (
                    <Box>
                      <Headline type="h2" color="secondary">
                        {this.state.faqKartenunddokumentenservice.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faqKartenunddokumentenserviceItems}
                      ></Accordion>
                    </Box>
                  )}

                {this.state.faq24hhotline &&
                  this.state.faq24hhotlineItems &&
                  this.state.faq24hhotlineItems.length > 0 && (
                    <Box>
                      <Headline type="h2" color="secondary">
                        {this.state.faq24hhotline.pageTitle}
                      </Headline>

                      <Accordion
                        items={this.state.faq24hhotlineItems}
                      ></Accordion>
                    </Box>
                  )}
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
