import { CashbackCategory } from '../../interfaces/cashbackCategory';
import { CashbackComplaint } from '../../interfaces/cashbackComplaint';
import { CashbackShoppingTransaction } from '../../interfaces/cashbackShoppingTransaction';
import { CashbackTicketTransaction } from '../../interfaces/cashbackTicketTransaction';
import { CashbackTravelTransaction } from '../../interfaces/cashbackTravelTransaction';
import { CashbackVoucherTransaction } from '../../interfaces/cashbackVoucherTransaction';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import { CreateCashbackComplaint } from '../../interfaces/createCashbackComplaint';
import { List } from '../../interfaces/list';
import Product from '../../interfaces/product';
import ProductOffer from '../../interfaces/productOffer';
import ProductSearchResult from '../../interfaces/productSearchResult';
import Shop from '../../interfaces/shop';
import Special from '../../interfaces/special';
import { API } from '../API';

export interface TransactionStatusSums {
  sumOpen: number;
  sumCancelled: number;
  sumConfirmed: number;
}

type CashbackState = 'open' | 'confirmed' | 'cancelled' | 'canceled';

type TransactionType =
  | CashbackShoppingTransaction
  | CashbackTicketTransaction
  | CashbackTravelTransaction;
export default class CashbackManager {
  static async createComplaint(data: any) {
    return await API.post(`cashback/complaints`, data, false);
  }

  static async findShops(params: any = {}): Promise<List<Shop>> {
    return await API.get(`cashback/shops?${API.encodeURL(params)}`);
  }

  static async findProducts(params: any = {}): Promise<ProductSearchResult> {
    return await API.get(`pap/products/search?${API.encodeURL(params)}`);
  }

  static async findShop(id: string): Promise<Shop> {
    return await API.get(`cashback/shops/${id}`);
  }

  static async findFavoriteShops(): Promise<any> {
    try {
      return await API.get(`cashback/shops/favorites`);
    } catch (error) {
      return [];
    }
  }

  static async findCategories(): Promise<List<CashbackCategory>> {
    return await API.get(`cashback/categories`);
  }

  static async findNewestShops(
    page = 1,
    itemsPerPage = 10
  ): Promise<List<Shop> | undefined> {
    try {
      return await API.get(
        `cashback/shops?page=${page}&itemsPerPage=${itemsPerPage}&orderBy=createdAt&direction=desc`
      );
    } catch (error) {
      return;
    }
  }

  static async findMostLikedShops(
    page = 1,
    itemsPerPage = 10
  ): Promise<List<Shop>> {
    return await API.get(
      `cashback/shops?page=${page}&itemsPerPage=${itemsPerPage}&orderBy=popularity&direction=desc`
    );
  }

  static async findProduct(id: string): Promise<Product> {
    return await API.get(`pap/products/${id}/detail`);
  }

  static async findProductOffers(
    unifiedProductId: string
  ): Promise<ProductOffer[]> {
    return await API.get(
      `pap/products/offers?unifiedProductId=${unifiedProductId}&query=`
    );
  }

  static async removeShopFavorite(id: string) {
    return await API.delete(`cashback/shops/${id}/favorite`);
  }

  static async setShopFavorite(id: string) {
    return await API.post(`cashback/shops/${id}/favorite`, {});
  }

  static async findSpecials(): Promise<Special[] | undefined> {
    try {
      const specials = await API.get(`cashback/specials`);

      if (specials) {
        return specials.filter((special: any) => {
          if (special.status !== 1) return false;
          try {
            if (new Date(special.end) < new Date()) return false;
            if (new Date(special.start) > new Date()) return false;
          } catch (e) {
            console.error(e);
          }
          return true;
        });
      }
    } catch (error) {
      return;
    }
  }

  static async findShopByCategoryId(
    id: any,
    page = 1,
    itemsPerPage = 10
  ): Promise<List<Shop>> {
    return await API.get(
      `cashback/categories/${id}/shops?page=${page}&itemsPerPage=${itemsPerPage}&orderBy=popularity&direction=desc`
    );
  }

  static async findLastClickoutShops(): Promise<Array<Shop>> {
    const clickouts: List<{
      createdAt: string;
      fkn: string;
      id: string;
      shop: Shop;
      subId: any;
    }> = await API.get('cashback/clickouts?itemsPerPage=100');

    return clickouts.items
      ? clickouts.items.map((clickout) => clickout.shop)
      : [];
  }

  static async findCashouts() {
    return await API.get(`cashback/cashouts`);
  }

  static async getCashoutProfile(): Promise<CashoutProfile> {
    try {
      return await API.get(`cashback/cashouts/profile`);
    } catch (error) {
      return {
        bankBalance: 0,
        collectedCash: 0,
        cancelledCash: 0,
        openCash: 0,
        paidOutCash: 0,
        externalPaidOutCash: 0,
        autoCashOutSubscribe: 0,
        recipient: 0,
      };
    }
  }

  static async complaint(data: CreateCashbackComplaint) {
    return await API.post(`cashback/complaints`, data);
  }

  static async findComplaints(): Promise<List<CashbackComplaint>> {
    return await API.get(
      'cashback/complaints?page=1&itemsPerPage=1000&orderBy=createdAt&direction=desc'
    );
  }

  static async getNumberOfComplaints(): Promise<number> {
    try {
      const complaints = await API.get(
        'cashback/complaints?page=1&itemsPerPage=1000'
      );

      if (complaints.items) {
        return complaints.items.filter(
          (complaint: CashbackComplaint) => complaint.status === 'open'
        ).length;
      }

      return 0;
    } catch (error) {
      return 0;
    }
  }

  static async getCashbackAmount(
    transactions: any
  ): Promise<TransactionStatusSums> {
    let sumOpen = 0;
    let sumConfirmed = 0;
    let sumCancelled = 0;

    if (transactions) {
      transactions.forEach((transaction: any) => {
        if (transaction.status === 'open') {
          sumOpen += transaction.cashOutAmount;
        }
        if (
          transaction.status === 'confirmed' ||
          transaction.status === 'collected'
        ) {
          sumConfirmed += transaction.cashOutAmount;
        }
        if (
          transaction.status === 'canceled' ||
          transaction.status === 'cancelled'
        ) {
          sumCancelled += transaction.cashOutAmount;
        }
      });
    }

    return { sumConfirmed, sumCancelled, sumOpen };
  }

  static async getCashbackAmountSums(
    cashbackStates: CashbackState[]
  ): Promise<{ [key in CashbackState]?: number }> {
    try {
      const openStatuses = ['open', 'confirmed'];
      const confirmedStatuses = ['collected'];

      const transactions = await Promise.all([
        this.getOnlineTransactions(),
        this.getTicketTransactions(),
        this.getTravelTransactions(),
        this.getVoucherTransactions(),
      ]);

      const result: { [key in CashbackState]?: number } = {};

      for (const cashbackState of cashbackStates) {
        const cashbackStateSum = transactions.reduce((sum, transactions) => {
          if (transactions.items) {
            transactions.items.forEach((transaction: TransactionType) => {
              if (cashbackState === 'open') {
                if (openStatuses.includes(transaction.status)) {
                  sum += transaction.cashOutAmount;
                }
              } else if (cashbackState === 'confirmed') {
                if (confirmedStatuses.includes(transaction.status)) {
                  sum += transaction.cashOutAmount;
                }
              }
            });
          }
          return sum;
        }, 0);

        result[cashbackState] = cashbackStateSum;
      }

      return result;
    } catch (error) {
      return {};
    }
  }

  static async getOnlineTransactions(
    startDate = '',
    endDate = ''
  ): Promise<List<CashbackShoppingTransaction>> {
    return await API.get(
      `cashback/transactions?page=1&itemsPerPage=1000&orderBy=date&direction=desc&${
        startDate ? 'startDate=' + startDate + '&' : ''
      }${endDate ? 'endDate=' + endDate + '&' : ''}`
    );
  }

  static async getTravelTransactions(
    startDate = '',
    endDate = ''
  ): Promise<List<CashbackTravelTransaction>> {
    return await API.get(
      `cashback/traveltransactions?page=1&itemsPerPage=1000&orderBy=date&direction=desc&${
        startDate ? 'startDate=' + startDate + '&' : ''
      }${endDate ? 'endDate=' + endDate + '&' : ''}`
    );
  }

  static async getTicketTransactions(
    startDate = '',
    endDate = ''
  ): Promise<List<CashbackTicketTransaction>> {
    return await API.get(
      `cashback/tickettransactions?page=1&itemsPerPage=1000&orderBy=date&direction=desc&${
        startDate ? 'startDate=' + startDate + '&' : ''
      }${endDate ? 'endDate=' + endDate + '&' : ''}`
    );
  }

  static async getVoucherTransactions(
    startDate = '',
    endDate = ''
  ): Promise<List<CashbackVoucherTransaction>> {
    return await API.get(
      `cashback/vouchertransactions?page=1&itemsPerPage=1000&orderBy=date&direction=desc&${
        startDate ? 'startDate=' + startDate + '&' : ''
      }${endDate ? 'endDate=' + endDate + '&' : ''}`
    );
  }
}
