import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

export const AppLoading = () => (
  <div
    style={{
      textAlign: "center",
      backgroundColor: "#e4eff7",
      height: "100vh",
      color: "#fff",
      justifyItems: "center",
      alignItems: "center",
      display: "flex",
    }}
  >
    <div
      style={{
        textAlign: "center",
        display: "block",
        width: "100%",
      }}
    >
      <img
        src="/assets/images/MeinPlusKonto.svg"
        alt=""
        height="50"
        width="283"
      />
      <br />
      <CircularProgress
        color="primary"
        style={{
          marginTop: "4em",
        }}
      />
    </div>
  </div>
);
