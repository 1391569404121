import './Login.scss';

import { faKey, faUser } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, Hidden, makeStyles, TextField } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Box from '../components/Box/Box';
import InnerLayout from '../components/InnerLayout/InnerLayout';
import { Layout } from '../components/Layout/Layout';
import Auth from '../services/auth';
import UserManager from '../services/manager/UserManager';

const useStyles = makeStyles((theme) => ({
  fullWidth: {
    width: "100%",
    display: "block",
    marginBottom: 10,
  },
  adornment: {
    height: "auto",
    width: "auto",
  },
  icon: {
    padding: 10,
    width: "40px!important",
    height: "40px!important",
  },
}));

function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const classes = useStyles();

  const login = () => {
    Auth.login(username, password).then((response) => {
      const LoginSwal = withReactContent(Swal);

      if (response === "invalidCredentials") {
        LoginSwal.fire({
          title: "Login fehlgeschlagen",
          text:
            "Anmeldung fehlgeschlagen. Bitte überprüfen Sie Ihre Anmeldedaten.",
          icon: "error",
          confirmButtonText: "OK",
          footer:
            !!username &&
            username.indexOf("@") !== -1 &&
            username.indexOf(".") !== -1 ? (
              <a onClick={() => handleResendRegisterMail()}>
                Bestätigungs-E-Mail erneut senden
              </a>
            ) : (
              false
            ),
        });
      } else if (response === "notVerified") {
        LoginSwal.fire({
          title: "Login fehlgeschlagen",
          text:
            "Sie haben sich noch nicht verifiziert. Bitte schauen Sie in Ihr E-Mail-Postfach bestätigen Sie Ihre Registrierung.",
          icon: "error",
          confirmButtonText: "OK",
          footer:
            !!username &&
            username.indexOf("@") !== -1 &&
            username.indexOf(".") !== -1 ? (
              <a onClick={() => handleResendRegisterMail()}>
                Bestätigungs-E-Mail erneut senden
              </a>
            ) : (
              false
            ),
        });
      }
    });
  };

  const handleResendRegisterMail = () => {
    const MySwal = withReactContent(Swal);

    UserManager.resendVerification(username).then(() => {
      MySwal.fire(
        "",
        "Wir haben Ihnen eine neue Bestätigungs-E-Mail gesendet. Bitte schauen Sie in Ihr E-Mail-Postfach bestätigen Sie Ihre Registrierung.",
        "success"
      );
    });
  };

  return (
    <Layout>
      <InnerLayout className="Login">
        <Box alternativeColor>
          <Box style={{ padding: "60px 120px 60px 120px" }}>
            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              wrap="nowrap"
              style={{ marginBottom: 30 }}
            >
              <Grid item>
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={faUser}
                  style={{ color: "#0b5aac" }}
                />
              </Grid>

              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  fullWidth={true}
                  value={username}
                  className={`${classes.fullWidth} loginField`}
                  onChange={(event) => setUsername(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) login();
                  }}
                  label="Benutzername (E-Mail-Adresse)"
                />
              </Grid>
            </Grid>

            <Grid
              container
              spacing={1}
              alignItems="flex-end"
              wrap="nowrap"
              style={{ marginBottom: 30 }}
            >
              <Grid item>
                <FontAwesomeIcon
                  className={classes.icon}
                  icon={faKey}
                  style={{ color: "#0b5aac" }}
                />
              </Grid>

              <Grid item style={{ flexGrow: 1 }}>
                <TextField
                  fullWidth={true}
                  value={password}
                  className={`${classes.fullWidth} loginField`}
                  onChange={(event) => setPassword(event.target.value)}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) login();
                  }}
                  label="Passwort"
                  type="password"
                />
              </Grid>
            </Grid>

            <Grid container>
              <Grid item sm={3} xs={12}>
                <NavLink to="/passwort-vergessen">Passwort vergessen?</NavLink>
              </Grid>

              <Grid item sm={9} xs={12}>
                <Typography align="right" className="loginButtons">
                  <Hidden smUp>
                    <Link to="/registrierung" className="buttonRegister">
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: 10 }}
                      >
                        Noch keinen Zugang?
                        <br />
                        Jetzt Registrieren
                      </Button>
                    </Link>
                  </Hidden>

                  <Hidden xsDown>
                    <Link to="/registrierung" className="buttonRegister">
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: 10 }}
                      >
                        Noch keinen Zugang? Jetzt Registrieren
                      </Button>
                    </Link>
                  </Hidden>

                  <Button
                    variant="contained"
                    color="primary"
                    onClick={login}
                    className="submitLogin"
                  >
                    Login Vorteilswelt
                  </Button>
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </InnerLayout>
    </Layout>
  );
}

export default Login;
