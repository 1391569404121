import './CashbackFilterBox.scss';

import { Grid } from '@material-ui/core';
import React, { Fragment } from 'react';

import Headline from '../Headline/Headline';
import InputWithButton from '../InputWithButton/InputWithButton';

interface Props {
  children?: any;
  title?: string;
  subTitle?: string;
  placeholder: string;
  buttonLabel: string;
  onSubmit: () => void;
  onResetFilter: () => void;
  onChange: (query: string) => void;
  query?: string;
  categoryFilter?: any;
  shopFilter?: any;
  quickFilter?: any;
  sortingSelect?: any;
  priceRangeFilter?: any;
}

export default function CashbackFilterBox(props: Props) {
  return (
    <div className="CashbackFilterBox">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {props.title && (
            <Headline type="h2" size="small">
              {props.title}
            </Headline>
          )}
          {props.subTitle && <p>{props.subTitle}</p>}
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <InputWithButton
                value={props.query}
                placeholder={props.placeholder}
                buttonLabel={props.buttonLabel}
                onClick={props.onSubmit}
                onChange={(e: any) => props.onChange(e.target.value)}
                onKeyDown={(event: any) =>
                  event.keyCode === 13 ? props.onSubmit() : null
                }
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {props.categoryFilter && (
                <Fragment>{props.categoryFilter}</Fragment>
              )}

              {props.shopFilter && <Fragment>{props.shopFilter}</Fragment>}
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              {props.sortingSelect && (
                <Fragment>{props.sortingSelect}</Fragment>
              )}
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={9} className="quickFilters">
              {props.quickFilter && <Fragment>{props.quickFilter}</Fragment>}
              {props.priceRangeFilter && (
                <Fragment>{props.priceRangeFilter}</Fragment>
              )}
            </Grid>

            <Grid item xs={12} md={3}>
              <div className="resetFilterWrapper">
                <span
                  className="resetFilterButton"
                  onClick={props.onResetFilter}
                >
                  Filter zurücksetzen
                </span>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
