import './Navigation.scss';

import { faSignOut } from '@fortawesome/pro-light-svg-icons';
import { faBars } from '@fortawesome/pro-regular-svg-icons';
import { faHome, faPhone, faSignIn } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Container, Hidden, Tooltip } from '@material-ui/core';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { User } from '../../interfaces/user';
import Auth from '../../services/auth';
import Helper from '../../services/helper';
import UserManager from '../../services/manager/UserManager';

interface Props {
  items: any[];
  toggleDrawer: any;
}
interface State {
  user?: User;
}

export default class Navigation extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
  }

  async componentDidMount() {
    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }
  }

  render() {
    return (
      <div className="navigation">
        <Container maxWidth="md">
          <div className="nav-wrapper">
            <div className="nav-item logo">
              <NavLink to={'/'}>
                <img src="/assets/images/MeinPlusKonto.svg" alt="" />
              </NavLink>
            </div>

            <Hidden mdDown>
              <div
                className="nav-item nav-item-list"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center',
                }}
              >
                <NavLink to={'/'}>
                  <img
                    src="/assets/images/logo_sauerland.svg"
                    alt=""
                    height="28"
                    style={{ verticalAlign: 'middle', display: 'inline-block' }}
                  />
                </NavLink>

                <NavLink to={'/kontakt'}>
                  <FontAwesomeIcon icon={faPhone} />
                  &nbsp;&nbsp;Kontakt
                </NavLink>
                {!Auth.isLoggedIn() && (
                  <NavLink to={'/login'}>
                    <FontAwesomeIcon icon={faSignIn} />
                    &nbsp;&nbsp;Login
                  </NavLink>
                )}
                {!!Auth.isLoggedIn() && (
                  <React.Fragment>
                    <NavLink to={'/profil'}>Profil</NavLink>
                    <NavLink to={'/abmelden'}>
                      <FontAwesomeIcon icon={faSignOut} />
                    </NavLink>
                  </React.Fragment>
                )}
              </div>
            </Hidden>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div className="nav-item">
                <div className="burger">
                  <Button onClick={() => this.props.toggleDrawer()}>
                    <FontAwesomeIcon icon={faBars} />
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Container>
        <div className="nav-wrapper subnav">
          <Container maxWidth="md">
            <Hidden mdDown>
              <div className="nav-item nav-item-list">
                <nav>
                  <div>
                    <NavLink
                      activeClassName="active"
                      isActive={() => window.location.pathname === '/'}
                      to={'/'}
                    >
                      <FontAwesomeIcon icon={faHome} />
                    </NavLink>
                  </div>
                  {this.props.items.map((item, index) => {
                    if (Auth.isLoggedIn() && item.title === 'Registrierung')
                      return null;
                    return (
                      <div key={'nav-' + index}>
                        {!Auth.isLoggedIn() && (
                          <NavLink activeClassName="active" to={item.link}>
                            {item.title}
                          </NavLink>
                        )}

                        {Auth.isLoggedIn() &&
                          this.state &&
                          this.state.user &&
                          this.state.user.userInfo &&
                          this.state.user.userInfo._aditoBasicContractType &&
                          Helper.canViewPage(
                            true,
                            item.title,
                            this.state.user.userInfo._aditoBasicContractType
                          ) === true && (
                            <NavLink activeClassName="active" to={item.link}>
                              {item.title}
                            </NavLink>
                          )}

                        {Auth.isLoggedIn() &&
                          this.state &&
                          this.state.user &&
                          this.state.user.userInfo &&
                          this.state.user.userInfo._aditoBasicContractType &&
                          Helper.canViewPage(
                            true,
                            item.title,
                            this.state.user.userInfo._aditoBasicContractType
                          ) === false && (
                            <Tooltip
                              arrow
                              title={
                                <span>
                                  Leider ist diese Funktion noch nicht für Sie
                                  freigeschaltet.
                                  <br />
                                  Bitte wenden Sie sich an Ihre/n Sparkassen
                                  Berater/in.
                                </span>
                              }
                            >
                              <span className="disabledNavigationItem">
                                {item.title}
                              </span>
                            </Tooltip>
                          )}
                      </div>
                    );
                  })}
                  {/* <div style={{ marginLeft: "auto" }}>
                    <NavLink activeClassName="active" to={"/cashback-radar"}>
                      Cashback-Radar&nbsp;&nbsp;
                      <FontAwesomeIcon icon={faCloudDownload} />
                    </NavLink>
                  </div>
                */}
                </nav>
              </div>
            </Hidden>
          </Container>
        </div>
      </div>
    );
  }
}
