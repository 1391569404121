import React, { Fragment } from 'react';

import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ServiceTile from '../../components/ServiceTile/ServiceTile';
import { ContentElements } from '../../interfaces/ContentElements';
import StateManager from '../../services/manager/StateManager';
import Box from './../../components/Box/Box';
import CmsManager from './../../services/manager/CmsManager';

interface Props {}

interface State {
  loading: boolean;
  content?: ContentElements;
  isFirefox: boolean;
  isChrome: boolean;
  buttonLabel: string;
  buttonLink: string;
}

export default class Contact extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      isFirefox: false,
      isChrome: false,
      buttonLabel: "",
      buttonLink: "",
    };
  }

  detectBrowser() {
    this.setState({
      isChrome: !!/(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.exec(
        navigator.userAgent
      ),
      isFirefox: !!/Firefox\/([0-9.]+)(?:\s|$)/.exec(navigator.userAgent),
    });
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const content = await CmsManager.getPageContentsCleaned("kontakt");

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="BonusRadar">
          <InnerLayout>
            {this.state.loading && <Loading />}

            {!this.state.loading && (
              <Fragment>
                <ImageSlider
                  images={[
                    {
                      src: "/assets/images/heroes/dummy.jpg",
                    },
                  ]}
                />

                <Box>
                  {this.state.content?.pageTitle && (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "Space-between",
                          alignItems: "center",
                          flexWrap: "wrap",
                          flexGrow: 1,
                        }}
                      >
                        <Headline type="h1" color="primary">
                          {this.state.content.pageTitle}
                        </Headline>

                        <ServiceTile></ServiceTile>
                      </div>

                      {this.state.content.pageDescription && (
                        <div style={{ width: "80%" }}>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: this.state.content
                                .pageDescription as string,
                            }}
                          ></p>
                        </div>
                      )}
                    </div>
                  )}

                  {this.state.content &&
                    Object.keys(this.state.content).map(
                      (item: string, index) => {
                        if (
                          this.state.content &&
                          item !== "pageTitle" &&
                          item !== "pageDescription" &&
                          item.endsWith("Title")
                        ) {
                          return (
                            <div key={index}>
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: this.state.content[
                                    item.slice(0, -5)
                                  ] as string,
                                }}
                              ></p>
                            </div>
                          );
                        } else {
                          return "";
                        }
                      }
                    )}
                </Box>
              </Fragment>
            )}
          </InnerLayout>
        </div>
      </Layout>
    );
  }
}
