import './InputWithButton.scss';

import { Button, InputBase } from '@material-ui/core';
import React from 'react';

interface Props {
  placeholder?: string;
  value?: string;
  onClick?: any;
  onChange?: any;
  onKeyDown?: any;
  buttonLabel?: string;
}

export default ({
  placeholder,
  buttonLabel,
  onClick,
  onChange,
  onKeyDown,
  value,
}: Props) => (
  <div className="InputWithButton">
    <InputBase
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      onKeyDown={onKeyDown}
    />
    <Button onClick={onClick} variant="contained" color={"primary"}>
      {buttonLabel || "Jetzt eintragen"}
    </Button>
  </div>
);
