export default class Helper {
  static formatNumber(value: number, decimals = 0) {
    value = parseFloat(value as any);
    return value
      .toFixed(decimals)
      .replace('.', ',')
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
  }

  static formatDate(date: any) {
    if (!date) return '';
    if (typeof date !== 'object') {
      if (date.indexOf('T') === -1) {
        date = date.replace(/-/g, '/');
      }
      date = new Date(date);
    }
    const prefix = (c: number) => (c < 10 ? `0${c}` : c);
    return `${prefix(date.getDate())}.${prefix(
      date.getMonth() + 1
    )}.${date.getFullYear()}`;
  }

  static formatTimestamp(timestamp: number) {
    return new Date(timestamp * 1000).toLocaleDateString('de-DE');
  }

  static dateToString(date?: Date | null) {
    if (!date) return undefined;
    return `${date.getFullYear()}-${('0' + (date.getMonth() + 1)).slice(-2)}-${(
      '0' + date.getDate()
    ).slice(-2)}`;
  }

  static formatPrice(value: number, currency?: string) {
    const price = value.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });

    if (!currency || ['EUR', 'eur', 'euro', 'Euro'].indexOf(currency) > -1)
      currency = 'EUR';

    return `${price} ${currency}`;
  }

  static canViewPage(isLoggedIn: boolean, route: string, accountType: string) {
    const blacklist: any = {
      // Flexibel Plus
      '5f1bed7d-d6bc-4342-9b75-e16cd0876247': [],
      // Digital Plus
      '92c920fa-a81a-4fca-9f57-d15f0874d883': [],
      // Premium Plus
      'a3df295a-b1b2-473f-9673-aaeb2c0bcb19': [],
      // MeinKonto 18 Plus
      'dcf9c0a4-1032-4293-ba8d-6906dcaeba19': [],
    };

    return isLoggedIn ? blacklist[accountType].indexOf(route) === -1 : true;
  }

  static canViewRoute(isLoggedIn: boolean, route: string, accountType: string) {
    const blacklist: any = {
      // Flexibel Plus
      '5f1bed7d-d6bc-4342-9b75-e16cd0876247': [],
      // Digital Plus
      '92c920fa-a81a-4fca-9f57-d15f0874d883': [],
      // Premium Plus
      'a3df295a-b1b2-473f-9673-aaeb2c0bcb19': [],
      // MeinKonto 18 Plus
      'dcf9c0a4-1032-4293-ba8d-6906dcaeba19': [],
    };

    return isLoggedIn ? blacklist[accountType].indexOf(route) === -1 : true;
  }
}
