import "./Security.scss";

import { Grid } from "@material-ui/core";
import React from "react";

import Box from "../../components/Box/Box";
import Headline from "../../components/Headline/Headline";
import ImageSlider from "../../components/ImageSlider/ImageSlider";
import InnerLayout from "../../components/InnerLayout/InnerLayout";
import { Layout } from "../../components/Layout/Layout";
import { Loading } from "../../components/Loading";
import ServiceTile from "../../components/ServiceTile/ServiceTile";
import { ContentElements } from "../../interfaces/ContentElements";
import { User } from "../../interfaces/user";
import Auth from "../../services/auth";
import CmsManager from "../../services/manager/CmsManager";
import StateManager from "../../services/manager/StateManager";
import UserManager from "../../services/manager/UserManager";

interface Props {
  location: any;
}

interface State {
  loading: boolean;
  content?: ContentElements;
  user?: User;
  matchingRoute: string;
}

export default class Security extends React.Component<Props, State> {
  private params: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
      matchingRoute: "",
    };
  }

  getParams = () => {
    this.params = this.props;

    this.setState({
      matchingRoute: this.params.match.params.id,
    });
  };

  scrollToElement() {
    const element = document
      .getElementById(window.location.hash.replace("#", ""))
      ?.getBoundingClientRect();

    if (element) {
      window.scrollTo({
        top: element ? element.top + window.scrollY : 0,
        left: 0,
        behavior: "smooth",
      });
    }
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    this.getParams();

    if (Auth.isLoggedIn()) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }

    const content = await CmsManager.getPageContentsCleaned("sicherheit");

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Security">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && this.state.content && (
            <div>
              <InnerLayout>
                <ImageSlider
                  images={[
                    {
                      src: "/assets/images/heroes/mehrwertleistungen.jpg",
                      contentBox: {
                        headline: this.state.content.pageTitle as string,
                        content: this.state.content.pageDescription as string,
                      },
                    },
                  ]}
                />

                <ServiceTile></ServiceTile>

                <Headline
                  type="h1"
                  styling="centered"
                  color="primary"
                  style={{ marginTop: "1.5em" }}
                >
                  Ihre Mehrwertleistungen für Ihre Sicherheit
                </Headline>

                <Grid container>
                  {Object.keys(this.state.content).map(
                    (item: string, index) => {
                      if (
                        this.state.content &&
                        item !== "pageTitle" &&
                        item !== "pageDescription" &&
                        !item.startsWith("sicherheitimageslider") &&
                        !item.startsWith("mitmehrsicherheitdurchsleben") &&
                        item.endsWith("Title")
                      ) {
                        return (
                          <Grid item xs={12} sm={6} style={{ padding: 5 }}>
                            <Box
                              alternativeColor
                              style={{
                                marginBottom: 16,
                                height: "100%",
                                padding: 0,
                              }}
                              id={item.slice(0, -5)}
                              key={index}
                              className="cms-content-box"
                            >
                              <div style={{ width: "100%", height: "330px" }}>
                                {this.state.content[
                                  `${item.slice(0, -5)}Files`
                                ] &&
                                  this.state.content[
                                    `${item.slice(0, -5)}Files`
                                  ].length > 0 && (
                                    <div
                                      style={{
                                        backgroundImage: `url(
                                        ${
                                          this.state.content[
                                            `${item.slice(0, -5)}Files`
                                          ][0].url
                                        } 
                                      )`,
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat",
                                        height: "100%",
                                        width: "100%",
                                      }}
                                    ></div>
                                  )}
                              </div>
                              <div style={{ width: "90%", padding: 30 }}>
                                <Headline type="h2">
                                  {this.state.content[item]}
                                </Headline>

                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content[
                                      item.slice(0, -5)
                                    ] as string,
                                  }}
                                ></p>
                              </div>
                            </Box>
                          </Grid>
                        );
                      } else {
                        return "";
                      }
                    }
                  )}
                </Grid>
              </InnerLayout>
            </div>
          )}
        </div>
      </Layout>
    );
  }
}
