import './Dashboard.scss';

import {
  faAngleRight,
  faLongArrowRight,
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid, TextField } from '@material-ui/core';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import Shop from '../../interfaces/shop';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import VoucherManager from '../../services/manager/VoucherManager';
import StateManager from '../../services/manager/StateManager';
import CmsManager from '../../services/manager/CmsManager';
import { ContentElements } from '../../interfaces/ContentElements';

interface Props {}

interface State {
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutProfile?: CashoutProfile;
  openComplaints?: number;
  favoriteShops: Shop[];
  loading: boolean;
  voucher?: string;
  content?: ContentElements;
}

export default class Dashboard extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      favoriteShops: [],
      loading: true,
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.submitVoucher = this.submitVoucher.bind(this);
    this.handleVoucherChange = this.handleVoucherChange.bind(this);
    this.fetchCashbackData = this.fetchCashbackData.bind(this);
  }

  async fetchCashbackData() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const [
      { confirmed: confirmedCashback, open: pendingCashback },
      cashoutProfile,
      openComplaints,
      favoriteShops,
    ] = await Promise.all([
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
      CashbackManager.getCashoutProfile(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.findFavoriteShops(),
    ]);

    this.setState({
      confirmedCashback,
      pendingCashback,
      cashoutProfile,
      openComplaints,
      favoriteShops: Array.isArray(favoriteShops) ? favoriteShops : [],
      loading: false,
    });
  }

  async componentDidMount() {
    this.setState({
      loading: false,
    });

    this.fetchCashbackData();

    const [content] = await Promise.all([
      CmsManager.getPageContentsCleaned('dashboard'),
    ]);

    this.setState({
      content,
    });
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.favoriteShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        favoriteShops: shops,
      });
    };
  }

  getBrowserName() {
    if (
      /(?!Chrom.*OPR)Chrom(?:e|ium)\/([0-9.]+)(:?\s|$)/.exec(
        navigator.userAgent
      )
    )
      return 'chrome';

    if (/Firefox\/([0-9.]+)(?:\s|$)/.exec(navigator.userAgent))
      return 'firefox';

    return '';
  }

  async submitVoucher() {
    this.setState({
      loading: true,
    });

    const LoginSwal = withReactContent(Swal);

    const response = await VoucherManager.submitVoucher(
      this.state.voucher || ''
    );

    const voucherValue =
      response.voucher &&
      response.voucher.value &&
      response.voucher.value.replace('.', ',');

    if (response.code === 200) {
      LoginSwal.fire({
        title: 'Erfolgreich',
        text: `${(this.state.content?.vouchersuccessmessageSubtitle ||
          '') as string} Ihr Gutscheincode im Wert von ${voucherValue} Euro wurde eingelöst.`,
        icon: 'success',
        confirmButtonText: 'OK',
        willClose: () => window.location.reload(),
      });
    } else if (response.code === 400) {
      LoginSwal.fire({
        title: 'Fehlgeschlagen',
        text:
          ((this.state.content?.voucherusedmessageSubtitle || '') as string) ||
          'Leider wurden bereits alle Gutscheine eingelöst. Die Aktion ist beendet',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    } else {
      LoginSwal.fire({
        title: 'Fehlgeschlagen',
        text:
          ((this.state.content?.vouchererrormessageSubtitle || '') as string) ||
          'Ups, da ist wohl etwas schiefgelaufen',
        icon: 'error',
        confirmButtonText: 'OK',
      });
    }

    this.setState({
      voucher: '',
      loading: false,
    });
  }

  handleVoucherChange = (event: any) => {
    this.setState({
      voucher: event.target.value || '',
    });
  };

  render() {
    return (
      <Layout>
        <div className="Dashboard">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box style={{ paddingTop: 0 }}>
                <div
                  className="title"
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <Headline type="h1" color="primary">
                    Ihre Daten
                  </Headline>

                  <Link to="/ihre-daten">
                    <Button variant="contained" color="primary">
                      Profil & Einstellungen
                    </Button>
                  </Link>
                </div>

                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Cashback Guthaben"
                      link="/transaktionen/uebersicht"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>bestätigt</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontSize: '2rem' }}>
                              {Helper.formatNumber(
                                this.state.confirmedCashback || 0,
                                2
                              )}{' '}
                              €
                            </p>
                          </div>
                        </div>

                        <div className="dataRow">
                          <div className="dataCell">
                            <p>offen</p>
                          </div>

                          <div className="dataCell">
                            <p>
                              {Helper.formatNumber(
                                this.state.pendingCashback || 0,
                                2
                              )}{' '}
                              €
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Auszahlungen"
                      link="/auszahlungen"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>ausgezahlt</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontSize: '2rem' }}>
                              {(this.state.cashoutProfile &&
                                Helper.formatPrice(
                                  this.state.cashoutProfile.paidOutCash +
                                    this.state.cashoutProfile
                                      .externalPaidOutCash
                                )) ||
                                Helper.formatPrice(0)}
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <DashboardBox
                      title="Nachbuchungsanfragen"
                      link="/nachbuchungsanfragen"
                      icon={faAngleRight}
                    >
                      <div className="dataWrapper">
                        <div className="dataRow">
                          <div className="dataCell">
                            <p>offene Anfragen</p>
                          </div>

                          <div className="dataCell">
                            <p style={{ fontSize: '2rem' }}>
                              {this.state.openComplaints || 0}
                            </p>
                          </div>
                        </div>
                      </div>
                    </DashboardBox>
                  </Grid>
                </Grid>
              </Box>

              <Box>
                <Headline type="h2">
                  {(this.state.content?.voucherheadlineSubtitle as string) ||
                    'Gutscheincode erhalten?'}
                </Headline>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <p>
                      {(this.state.content
                        ?.voucherdescriptionSubtitle as string) ||
                        'Lösen Sie Ihren Gutscheincode für Ihren Einkauf unter "Shops und Produkte" ein.'}
                    </p>
                    <Grid container spacing={4}>
                      <Grid item xs={12} md={9}>
                        <TextField
                          onChange={(event: any) =>
                            this.handleVoucherChange(event)
                          }
                          placeholder={
                            (this.state.content
                              ?.voucherplaceholderSubtitle as string) ||
                            'Gutscheincode'
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <div className="buttonWrapper">
                          <Button
                            variant="outlined"
                            color="primary"
                            onClick={this.submitVoucher}
                            style={{ marginTop: '-18px' }}
                          >
                            <span>
                              {(this.state.content
                                ?.voucherbuttontextSubtitle as string) ||
                                'Jetzt Gutschein einlösen'}
                            </span>
                            <FontAwesomeIcon icon={faLongArrowRight} />
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>

              {this.state.favoriteShops?.length > 0 && (
                <Grid container spacing={4} style={{ marginTop: '50px' }}>
                  <Grid item xs={12}>
                    <Headline type="h2" styling="centered" color="primary">
                      Ihre Cashback-Favoriten
                    </Headline>
                    <Grid container spacing={2}>
                      {this.state.favoriteShops?.map((shop: Shop, key) => (
                        <Grid item xs={6} md={3} key={key}>
                          <ShopTile
                            image={shop.logo}
                            buttonLabel="Zum Shop"
                            morePath={`/shop/${shop.id}`}
                            buttonLink={shop.link}
                            isFavorite={shop.isFavorite}
                            onFavoriteChange={this.handleFavoriteChange(shop)}
                          >
                            {shop.maximumCashback && (
                              <Fragment>
                                Bis zu{' '}
                                <span>
                                  {Helper.formatNumber(shop.maximumCashback, 2)}{' '}
                                  {shop.maximumCashbackType}
                                </span>{' '}
                                bei {shop.name}
                              </Fragment>
                            )}
                          </ShopTile>
                        </Grid>
                      ))}
                    </Grid>

                    <div
                      style={{
                        textAlign: 'center',
                        padding: '1em',
                        paddingTop: '3em',
                      }}
                    >
                      <Link
                        to={{
                          pathname: '/cashback/shops',
                          state: {
                            filterState: {
                              direction: 'desc',
                              filters: ['favorites'],
                              favorites: true,
                              orderBy: 'popularity',
                              page: 1,
                            },
                          },
                        }}
                        className="moreLink"
                      >
                        <FontAwesomeIcon
                          icon={faAngleRight}
                          style={{
                            marginRight: 10,
                          }}
                        />
                        Alle Cashback-Favoriten anzeigen
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              )}
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
