import './Layout.scss';

import { createMuiTheme, Divider, Drawer, List, ListItem, ThemeProvider } from '@material-ui/core';
import * as React from 'react';
import { NavLink } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import { User } from '../../interfaces/user';
import { footerItems, navigationItems } from '../../routes';
import { Alert } from '../../services/alert';
import Auth from '../../services/auth';
import UserManager from '../../services/manager/UserManager';
import Navigation from '../Navigation/Navigation';
import Footer from './../Footer/Footer';
import Head from './Head';

interface Props {
  title?: string;
  location?: any;
}

interface State {
  isDrawerOpen: boolean;
  loggedIn: boolean;
  loading: boolean;
  user?: User;
}

export const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#eb6507",
      main: "#eb6507",
      dark: "#eb6507",
      contrastText: "#fff",
    },
    secondary: {
      main: "#0B5AAC",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 1140,
      lg: 1280,
      xl: 1920,
    },
  },
});

export class Layout extends React.Component<Props, State> {
  public navigation = navigationItems;
  public footer = footerItems;

  constructor(props: any) {
    super(props);

    this.state = {
      isDrawerOpen: false,
      loggedIn: false,
      loading: false,
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);
    this.closeDrawer = this.closeDrawer.bind(this);
  }

  async componentDidMount() {
    await this.setState({
      loggedIn: await Auth.checkLogin(),
    });

    let user;

    if (this.state.loggedIn) {
      user = await UserManager.me();

      this.setState({
        user,
      });
    }

    if (
      window.location.pathname !== "/datenschutz" &&
      user &&
      user.userInfo &&
      user.userInfo.hasAcceptedPrivacyUpdate !== "1"
    ) {
      this.forceUpdatePrivacy();
    }
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      "Ein Fehler ist aufgetreten",
      "Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers."
    );
  }

  toggleDrawer() {
    this.setState({ isDrawerOpen: !this.state.isDrawerOpen });
  }

  closeDrawer() {
    this.setState({ isDrawerOpen: false });
  }

  async forceUpdatePrivacy() {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      icon: "warning",
      title: "Änderung der Datenschutzinformationen",
      html: `Wir haben unsere Datenschutzinformationen aktualisiert, damit Sie leichter nachvollziehen können wie wir Ihre personenbezogenen Daten verarbeiten. Um die Mehrwerte Ihres Vorteilskontos weiterhin in vollem Umfang nutzen zu können, bitten wie Sie die aktualisierten <a href="/datenschutz" target="_blank">Datenschutzinformationen</a> zu lesen und zu akzeptieren.`,
      confirmButtonText:
        "Ich habe die Datenschutzinformationen gelesen und akzeptiere diese.",
      footer: (
        <a href="/datenschutz" target="_blank">
          Zu den aktualisierten Datenschutzinformationen
        </a>
      ),
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      focusConfirm: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updated = await UserManager.updatePrivacy();

        if (updated) {
          MySwal.fire({
            icon: "success",
            title: "Vielen Dank",
            confirmButtonText: "Schließen",
            timer: 5000,
            timerProgressBar: true,
            focusConfirm: false,
          });
        }
      }
    });
  }

  render() {
    if (!this.state) return;

    const footer = this.footer;

    if (!Auth.isLoggedIn()) {
      footer.splice(4, 1);
    }

    return (
      <ThemeProvider theme={theme}>
        <Head>
          {Auth.isLoggedIn() && <meta name="fkn" content={Auth.getFKN()} />}
        </Head>

        <div className="layout">
          <Drawer
            onBackdropClick={this.closeDrawer}
            anchor="right"
            open={this.state.isDrawerOpen}
          >
            <List>
              {this.navigation.map((item, index) => (
                <ListItem key={"nav-item-" + index} button>
                  <NavLink
                    style={{ width: "100%", margin: "6px 0" }}
                    key={"nav-" + index}
                    activeClassName="active"
                    to={item.link}
                  >
                    {item.title}
                  </NavLink>
                </ListItem>
              ))}

              <ListItem button>
                <NavLink
                  style={{ width: "100%", margin: "6px 0" }}
                  activeClassName="active"
                  to={"/kontakt"}
                >
                  Kontakt
                </NavLink>
              </ListItem>

              {Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: "100%", margin: "6px 0" }}
                    activeClassName="active"
                    to={"/profil"}
                  >
                    Profil
                  </NavLink>
                </ListItem>
              )}

              {!Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: "100%", margin: "6px 0" }}
                    activeClassName="active"
                    to={"/login"}
                  >
                    Login
                  </NavLink>
                </ListItem>
              )}

              <Divider />

              {Auth.isLoggedIn() && (
                <ListItem button>
                  <NavLink
                    style={{ width: "100%", margin: "6px 0 0" }}
                    activeClassName="active"
                    to={"/abmelden"}
                  >
                    Abmelden
                  </NavLink>
                </ListItem>
              )}

              <img
                src="/assets/images/logo_sauerland_weiss.svg"
                alt=""
                height="28"
                style={{
                  verticalAlign: "middle",
                  display: "inline-block",
                  marginLeft: 15,
                  marginTop: 15,
                }}
              />
            </List>
          </Drawer>

          <Navigation
            items={this.navigation}
            toggleDrawer={this.toggleDrawer}
          />

          <div className="content-wrapper">{this.props.children}</div>

          <Footer items={this.footer} />
        </div>
      </ThemeProvider>
    );
  }
}
