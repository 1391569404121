import "./SpecialTile.scss";

import { Grid } from "@material-ui/core";
import React from "react";

import { AuthButton } from "../AuthButton";
import Headline from "../Headline/Headline";

interface Props {
  children?: any;
  image?: string;

  title?: string;
  subTitle?: string;
  info?: string;

  buttonLabel?: string;
  buttonLink?: string;

  priceActual?: string;
  priceBefore?: string;
  specialText?: string;
}

export default function SpecialTile(props: Props) {
  return (
    <div className="SpecialTile">
      {props.image && props.buttonLink && (
        <a
          href={props.buttonLink}
          target="_blank"
          style={{ width: "100%" }}
          rel="noopener noreferrer"
        >
          <img src={props.image} alt="" style={{ width: "100%" }} />
        </a>
      )}

      <div className="SpecialTile-main">
        {props.title && (
          <Headline type="h2" color="secondary">
            {props.title}
          </Headline>
        )}

        {props.priceActual && props.priceActual !== "0" && (
          <Headline
            type="h4"
            color="primary"
            style={{ fontWeight: "normal", margin: 0 }}
            classNames="SpecialTile-actualPrice"
          >
            {props.priceActual} {props.specialText && "*"}
          </Headline>
        )}

        {props.priceBefore && (
          <Headline type="h2" size="small" classNames="SpecialTile-priceBefore">
            {props.priceBefore}
          </Headline>
        )}

        {props.subTitle && (
          <div className="SpecialTile-subTitle">{props.subTitle}</div>
        )}

        {props.specialText && (
          <div className="SpecialTile-content">* {props.specialText}</div>
        )}
      </div>

      <div className="SpecialTile-footer">
        <Grid container spacing={2}>
          <Grid item xs={6}>
            {props.info && (
              <div className="SpecialTile-info" style={{ color: "#0b5aac" }}>
                {props.info}
              </div>
            )}
          </Grid>

          <Grid
            item
            xs={6}
            style={{
              display: "flex",
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
          >
            {props.buttonLabel && props.buttonLink && (
              <AuthButton
                color="primary"
                variant="contained"
                href={props.buttonLink ? props.buttonLink : ""}
                target="_blank"
                style={{ marginBottom: 8 }}
              >
                {props.buttonLabel}
              </AuthButton>
            )}
          </Grid>
        </Grid>
      </div>
    </div>
  );
}
