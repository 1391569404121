import './Home.scss';

import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Grid } from '@material-ui/core';
import * as React from 'react';
import { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import ImageSlider from '../../components/ImageSlider/ImageSlider';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ShopTile from '../../components/ShopTile/ShopTile';
import SpecialTile from '../../components/SpecialTile/SpecialTile';
import { ContentElements } from '../../interfaces/ContentElements';
import Shop from '../../interfaces/shop';
import Special from '../../interfaces/special';
import { Alert } from '../../services/alert';
import Config from '../../services/config';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import CmsManager from '../../services/manager/CmsManager';
import NewsletterManager from '../../services/manager/NewsletterManager';
import StateManager from '../../services/manager/StateManager';

interface Props {}

interface State {
  cashbackShops: Shop[];
  specials?: Special[];
  loading: boolean;
  redirectToSearch: boolean;
  query: string;
  newsletterEmail: string;
  fashionId: string;
  content?: ContentElements;
}

export class Home extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      cashbackShops: [],
      specials: [],
      loading: true,
      redirectToSearch: false,
      query: '',
      newsletterEmail: '',
      fashionId: '',
    };

    this.handleFavoriteChange = this.handleFavoriteChange.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.handleChangeQuery = this.handleChangeQuery.bind(this);
    this.handleChangeNewsletterEmail = this.handleChangeNewsletterEmail.bind(
      this
    );
    this.handleSubscribeNewsletter = this.handleSubscribeNewsletter.bind(this);
    this.fetchCashbackData = this.fetchCashbackData.bind(this);
  }

  handleFavoriteChange(shop: Shop) {
    return async (isFavorite: boolean) => {
      // Update shop
      if (!isFavorite) {
        await CashbackManager.removeShopFavorite(shop.id);
      } else {
        await CashbackManager.setShopFavorite(shop.id);
      }

      // Update view
      const shops = this.state.cashbackShops;

      shops.forEach((s: Shop) => {
        if (s.id === shop.id) {
          s.isFavorite = isFavorite;
        }
      });

      this.setState({
        cashbackShops: shops,
      });
    };
  }

  async fetchCashbackData() {
    const [specials, cashbackShops] = await Promise.all([
      CashbackManager.findSpecials(),
      CashbackManager.findNewestShops(1, 4),
    ]);

    if (cashbackShops && typeof cashbackShops.items === 'object')
      cashbackShops.items = Object.values(cashbackShops.items);

    this.setState({
      cashbackShops: cashbackShops?.items || [],
      specials: specials?.slice(0, 2),
    });
  }

  async componentDidMount() {
    this.setState({
      loading: false,
    });

    this.fetchCashbackData();

    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const fashionId = Config.getFashionCategoryId();

    const [content] = await Promise.all([
      CmsManager.getPageContentsCleaned('startseite'),
    ]);

    this.setState({
      loading: false,
      fashionId,
      content,
    });
  }

  handleSearch() {
    this.setState({
      redirectToSearch: true,
    });
  }

  handleChangeQuery(query: string) {
    this.setState({
      query,
    });
  }

  handleChangeNewsletterEmail(e: any) {
    this.setState({
      newsletterEmail: e.target.value,
    });
  }

  async handleSubscribeNewsletter() {
    if (this.state.newsletterEmail.trim() === '') return;

    const response = await NewsletterManager.subscribe(
      this.state.newsletterEmail
    );

    if (response) {
      Alert.success(
        'Newsletter',
        'Vielen Dank für Ihre Anmeldung zum Newsletter!'
      );
    } else {
      Alert.error(
        'Newsletter',
        'Bitte überprüfen Sie die angegebene E-Mail-Adresse.'
      );
    }

    this.setState({
      newsletterEmail: '',
    });
  }

  render() {
    if (this.state.redirectToSearch)
      return <Redirect to={`/shops-und-produkte?q=${this.state.query}`} />;

    return (
      <Layout>
        <div className="Home">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <React.Fragment>
              <InnerLayout>
                <ImageSlider
                  images={[
                    {
                      src: '/assets/images/heroes/home.jpg',
                      contentBox: {
                        headline:
                          'Plus-Leistungen für Mitglieder der Volksbank Sauerland',
                        subline:
                          'Über unsere VR-Kontomodelle Plus möchten wir für Sie die Mitgliedschaft erlebbar machen.',
                      },
                    },
                  ]}
                />
              </InnerLayout>
              <InnerLayout className="colorful">
                {((this.state.specials?.length || 0) > 0 ||
                  this.state.cashbackShops.length > 0) && (
                  <Box alternativeColor>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        {(this.state?.specials?.length || 0) > 0 && (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Headline
                                type="h2"
                                styling="centered"
                                color="primary"
                              >
                                Die neuesten Cashback-Highlights
                              </Headline>
                            </Grid>

                            {this.state.specials?.map((special, key) => (
                              <Grid item xs={12} sm={6} key={key}>
                                <SpecialTile
                                  image={special.image}
                                  title={special.title}
                                  subTitle={special.subTitle}
                                  info={
                                    special.end
                                      ? `Nur bis zum ${Helper.formatDate(
                                          special.end
                                        )}`
                                      : undefined
                                  }
                                  buttonLabel="Zum Shop"
                                  buttonLink={special.link}
                                  priceActual={special.priceActual}
                                  priceBefore={special.priceBefore}
                                  specialText={special.text}
                                ></SpecialTile>
                              </Grid>
                            ))}

                            <Grid
                              item
                              xs={12}
                              style={{
                                textAlign: 'center',
                                padding: '2em',
                                paddingBottom: '4em',
                              }}
                            >
                              <Link to="/angebote" className="moreLink">
                                <FontAwesomeIcon
                                  icon={faAngleRight}
                                  style={{
                                    marginRight: 5,
                                  }}
                                />
                                Alle Cashback-Highlights anzeigen
                              </Link>
                            </Grid>
                          </Grid>
                        )}

                        {this.state.cashbackShops.length > 0 && (
                          <Grid container spacing={2}>
                            <Grid item xs={12}>
                              <Headline
                                type="h2"
                                styling="centered"
                                color="primary"
                              >
                                Die neusten Cashback-Shops
                              </Headline>
                            </Grid>

                            {this.state.cashbackShops?.map(
                              (shop: Shop, key) => (
                                <Grid item xs={6} md={3} key={key}>
                                  <ShopTile
                                    image={shop.logo}
                                    buttonLabel="Zum Shop"
                                    morePath={`/shop/${shop.id}`}
                                    buttonLink={shop.link}
                                    isFavorite={shop.isFavorite}
                                    onFavoriteChange={this.handleFavoriteChange(
                                      shop
                                    )}
                                  >
                                    {shop.maximumCashback && (
                                      <Fragment>
                                        Bis zu{' '}
                                        <span>
                                          {Helper.formatNumber(
                                            shop.maximumCashback,
                                            2
                                          )}{' '}
                                          {shop.maximumCashbackType}
                                        </span>{' '}
                                        bei {shop.name}
                                      </Fragment>
                                    )}
                                  </ShopTile>
                                </Grid>
                              )
                            )}

                            <Grid
                              xs={12}
                              item
                              style={{
                                textAlign: 'center',
                                padding: '2em',
                              }}
                            >
                              <Link to="/cashback/shops" className="moreLink">
                                <FontAwesomeIcon
                                  icon={faAngleRight}
                                  style={{
                                    marginRight: 5,
                                  }}
                                />
                                Alle Cashback-Shops anzeigen
                              </Link>
                            </Grid>
                          </Grid>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                )}

                {this.state.content && this.state.content.cashbackradarTitle && (
                  <Box>
                    <Grid className="extensionTeaser" container>
                      <Grid item xs={12} sm={7}>
                        <div
                          style={{
                            backgroundImage:
                              'url(/assets/images/heroes/dummy.jpg)',
                            height: '100%',
                            backgroundSize: 'cover',
                            minHeight: 120,
                          }}
                        ></div>
                      </Grid>

                      <Grid item xs={12} sm={5}>
                        <Box alternativeColor style={{ textAlign: 'center' }}>
                          {this.state.content.cashbackradarTitle && (
                            <Headline type="h3" color="secondary">
                              {this.state.content.cashbackradarTitle}
                            </Headline>
                          )}

                          {this.state.content.cashbackradarSubtitle && (
                            <Headline type="h4" color="secondary">
                              {this.state.content.cashbackradarSubtitle}
                            </Headline>
                          )}
                          {this.state.content.cashbackradar && (
                            <p
                              dangerouslySetInnerHTML={{
                                __html: this.state.content
                                  .cashbackradar as string,
                              }}
                            ></p>
                          )}

                          <Link to={'/cashback-radar'}>
                            <Button color="primary" variant="contained">
                              Jetzt herunterladen
                            </Button>
                          </Link>
                        </Box>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </InnerLayout>
            </React.Fragment>
          )}
        </div>
      </Layout>
    );
  }
}
