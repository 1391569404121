import './Box.scss';

import React, { CSSProperties } from 'react';

interface Props {
  children: any;
  border?: boolean;
  style?: CSSProperties;
  className?: string;
  alternativeColor?: boolean;
  id?: string;
}

export default function Box(props: Props) {
  return (
    <div
      className={`Box ${props.alternativeColor ? "colorful" : ""} ${
        props.className
      }`}
      style={props.style}
      id={props.id}
    >
      {props.children}
    </div>
  );
}
