import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default function ScrollToTop() {
  const { pathname, hash } = useLocation();

  useEffect(() => {
    const scrollToElement = () => {
      const element = document
        .getElementById(hash.replace('#', ''))
        ?.getBoundingClientRect();

      if (element) {
        window.scrollTo({
          top: element ? element.top + window.scrollY : 0,
          left: 0,
          behavior: 'smooth',
        });
      }
    };

    if (hash) {
      const element = document.getElementById(hash.replace('#', ''));

      if (element) {
        scrollToElement();
      } else {
        setTimeout(scrollToElement, 1000);
      }
    } else {
      window.scrollTo(0, 0);
    }
  }, [pathname, hash]);

  return null;
}
