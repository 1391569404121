import './Special.scss';

import { Grid } from '@material-ui/core';
import React from 'react';

import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import SpecialTile from '../../components/SpecialTile/SpecialTile';
import SpecialInterface from '../../interfaces/special';
import helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';

interface Props {}

interface State {
  specials?: SpecialInterface[];
  loading: boolean;
}

export default class Special extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      specials: [],
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const specials = await CashbackManager.findSpecials();

    this.setState({
      specials,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="Special">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout className="colorful" style={{ marginTop: 20 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Headline type="h2" styling="centered" color="primary">
                    Die neuesten Cashback-Highlights
                  </Headline>
                </Grid>

                {this.state.specials?.map((special: SpecialInterface, key) => (
                  <Grid item xs={12} md={6} key={key}>
                    <SpecialTile
                      image={special.image}
                      title={special.title}
                      subTitle={special.subTitle}
                      info={
                        special.end
                          ? `Nur bis zum ${helper.formatDate(special.end)}`
                          : undefined
                      }
                      buttonLabel="Zum Shop"
                      buttonLink={special.link}
                      priceActual={special.priceActual}
                      priceBefore={special.priceBefore}
                      specialText={special.text}
                    ></SpecialTile>
                  </Grid>
                ))}
              </Grid>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
