import './App.scss';

import * as React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import { AppLoading } from './components/AppLoading';
import PrivateRoute from './components/PrivateRoute';
import ScrollToTop from './components/ScrollToTop';
import ProductDetail from './features/Cashback/ProductDetail';
import ShopDetail from './features/Cashback/ShopDetail';
import { Error404 } from './features/Error404';
import { Home } from './features/Home/Home';
import InfoPage from './features/InfoPage/InfoPage';
import Registration from './features/Registration/Registration';
import SetNewPassword from './features/SetNewPassword';
import UnsubscribeFromNewsletter from './features/UnsubscribeFromNewsletter';
import VerifyAccount from './features/VerifyAccount';
import { User } from './interfaces/user';
import {
  footerItems,
  hiddenRoutes,
  navigationItems,
  Route as RouteInterface,
} from './routes';
import { Alert } from './services/alert';
import Auth from './services/auth';
import Helper from './services/helper';
import UserManager from './services/manager/UserManager';

interface State {
  loggedIn: boolean;
  loading: boolean;
  user?: User;
}

class App extends React.Component<{}, State> {
  constructor(props: {}) {
    super(props);

    this.state = {
      loggedIn: false,
      loading: true,
    };
  }

  componentDidCatch(error: Error | null, info: object) {
    Alert.error(
      'Ein Fehler ist aufgetreten',
      'Bitte versuchen Sie es später noch einmal. Wir arbeiten mit Hochdruck an der Behebung des Fehlers.'
    );
  }

  async componentDidMount() {
    this.setState({
      loggedIn: await Auth.checkLogin(),
      loading: false,
    });

    if (this.state.loggedIn) {
      const user = await UserManager.me();

      this.setState({
        user,
      });
    }
  }

  render() {
    if (this.state.loading) return <AppLoading />;

    return (
      <div>
        <BrowserRouter basename="/">
          <ScrollToTop />

          <Switch>
            <Route exact path="/" component={Home} />

            {[...navigationItems, ...footerItems, ...hiddenRoutes].map(
              (item: RouteInterface, index) => {
                if (item.component) {
                  const attr = {
                    exact: true,
                    key: 'route-' + index,
                    path: item.link,
                    component: item.component,
                  };

                  if (
                    this.state.user &&
                    !Helper.canViewRoute(
                      this.state.loggedIn,
                      item.link,
                      this.state.user?.userInfo._aditoBasicContractType
                    )
                  ) {
                    return (
                      <Route exact path={item.link}>
                        <Redirect to="/" />
                      </Route>
                    );
                  } else if (item.private) {
                    return <PrivateRoute {...attr} />;
                  } else {
                    return <Route {...attr} />;
                  }
                }

                return false;
              }
            )}

            <Route exact path="/produkt/:id" component={ProductDetail} />
            <Route exact path="/shop/:id" component={ShopDetail} />
            <Route exact path="/info/:id" component={InfoPage} />
            <Route exact path="/registrierung/:type" component={Registration} />
            <Route exact path="/cashback">
              <Redirect to="/cashback/shops" />
            </Route>
            <Route
              exact
              path="/passwort-zuruecksetzen"
              component={SetNewPassword}
            />
            <Route exact path="/konto-aktivieren" component={VerifyAccount} />
            <Route
              exact
              path="/newsletter/abmeldung"
              component={UnsubscribeFromNewsletter}
            />
            <Route component={Error404} />
          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
