import './Dashboard.scss';

import { faAngleRight } from '@fortawesome/pro-light-svg-icons';
import { Grid } from '@material-ui/core';
import React from 'react';

import Backlink from '../../components/Backlink/Backlink';
import Box from '../../components/Box/Box';
import DashboardBox from '../../components/DashboardBox/DashboardBox';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import ResponsiveTable from '../../components/ResponsiveTable/ResponsiveTable';
import { Cashout } from '../../interfaces/cashout';
import { CashoutProfile } from '../../interfaces/cashoutProfile';
import Helper from '../../services/helper';
import CashbackManager from '../../services/manager/CashbackManager';
import StateManager from '../../services/manager/StateManager';

interface Props {}

interface State {
  cashouts: Cashout[];
  cashoutProfile?: CashoutProfile;
  confirmedCashback?: number;
  pendingCashback?: number;
  cashoutSum: number;
  loading: boolean;
  openComplaints: number;
  tableData: any[];
}

export default class Cashouts extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      openComplaints: 0,
      cashouts: [],
      cashoutSum: 0,
      loading: true,
      tableData: [],
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const result = await Promise.all([
      CashbackManager.findCashouts(),
      CashbackManager.getNumberOfComplaints(),
      CashbackManager.getCashbackAmountSums(['confirmed', 'open']),
    ]);

    const cashouts =
      result[0].items && result[0].items.length > 0 ? result[0].items : [];

    let tableData: any[] = [];

    if (cashouts.length > 0) {
      tableData = cashouts;
    }

    const totalSum = this.getCashoutSum(cashouts);

    tableData.push({
      date: '',
      sum: totalSum,
    });

    this.setState({
      cashouts: cashouts,
      cashoutProfile: result[0],
      openComplaints: result[1],
      confirmedCashback: result[2].confirmed,
      pendingCashback: result[2].open,
      loading: false,
      tableData,
    });
  }

  getCashoutSum(cashouts: Cashouts[]) {
    let cashoutSum = 0;

    if (cashouts.length > 0) {
      cashouts.forEach((cashout: any) => {
        cashoutSum += cashout.sum;
      });
    }

    return cashoutSum;
  }

  render() {
    return (
      <Layout>
        <div className="Dashboard">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                <Backlink label="zurück zur Übersicht" to="/profil" />
              </Box>

              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Grid item xs={12}>
                      <Headline type="h1" color="primary">
                        Ihre Auszahlungen
                      </Headline>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={8}>
                    <Grid item xs={12}>
                      {this.state.tableData &&
                        this.state.cashouts &&
                        this.state.cashouts.length > 0 && (
                          <ResponsiveTable
                            lastRowIsSumRow
                            columns={[
                              {
                                name: 'date',
                                label: 'Auszahlungsdatum',
                                notCollapseOnMobile: true,
                                textAlign: 'left',
                              },
                              {
                                name: 'sum',
                                label: 'Auszahlungssumme',
                                notCollapseOnMobile: true,
                                textAlign: 'right',
                              },
                            ]}
                            data={
                              this.state.tableData &&
                              this.state.tableData.map(
                                (cashout: any, index) => {
                                  return {
                                    date: Helper.formatDate(cashout.date),
                                    sum: Helper.formatPrice(cashout.sum),
                                  };
                                }
                              )
                            }
                          />
                        )}

                      {this.state.tableData &&
                        this.state.cashouts &&
                        this.state.cashouts.length === 0 && (
                          <ResponsiveTable
                            lastRowIsSumRow
                            columns={[
                              {
                                name: 'date',
                                label: 'Auszahlungsdatum',
                                notCollapseOnMobile: true,
                                textAlign: 'left',
                              },
                              {
                                name: 'sum',
                                label: 'Auszahlungssumme',
                                notCollapseOnMobile: true,
                                textAlign: 'right',
                              },
                            ]}
                            data={[
                              {
                                date: 'Keine Einträge vorhanden',
                                sum: '0,00 EUR',
                              },
                            ]}
                          />
                        )}
                    </Grid>
                  </Grid>

                  <Grid item xs={12} md={4}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Cashback Guthaben"
                          link="/transaktionen/uebersicht"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>bestätigt</p>
                              </div>

                              <div className="dataCell">
                                <p>
                                  {Helper.formatPrice(
                                    this.state.confirmedCashback || 0,
                                    'EUR'
                                  )}
                                </p>
                              </div>
                            </div>

                            <div className="dataRow">
                              <div className="dataCell">
                                <p>offen</p>
                              </div>

                              <div className="dataCell">
                                <p>
                                  {Helper.formatPrice(
                                    this.state.pendingCashback || 0,
                                    'EUR'
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>

                      <Grid item xs={12} sm={6} md={12}>
                        <DashboardBox
                          title="Nachbuchungsanfragen"
                          link="/nachbuchungsanfragen"
                          icon={faAngleRight}
                          placedOnSidebar
                        >
                          <div className="dataWrapper">
                            <div className="dataRow">
                              <div className="dataCell">
                                <p>offene Anfragen</p>
                              </div>

                              <div className="dataCell">
                                <p>{this.state.openComplaints || 0}</p>
                              </div>
                            </div>
                          </div>
                        </DashboardBox>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
