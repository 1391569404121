import "./DownloadList.scss";

import { faArrowToBottom } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment } from "react";

import { DownloadLink } from "../../interfaces/downloadLink";
import Headline from "../Headline/Headline";

interface Props {
  links: DownloadLink[];
}

export default class DownloadList extends React.Component<Props, {}> {
  render() {
    return (
      <ul className="DownloadList">
        {this.props.links &&
          this.props.links.map((link: DownloadLink) => (
            <Fragment>
              {link.active === true && link.label && link.link && (
                <li>
                  <a
                    href={link.link}
                    target={link.target === "_blank" ? "_blank" : ""}
                  >
                    <FontAwesomeIcon
                      icon={faArrowToBottom}
                      style={{
                        fontSize: 20,
                        marginRight: 10,
                      }}
                    />

                    <Headline type="h3" styling="skinny">
                      {link.label}
                    </Headline>
                  </a>
                </li>
              )}
            </Fragment>
          ))}
      </ul>
    );
  }
}
