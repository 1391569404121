import "./ImageSlider.scss";
import "swiper/components/pagination/pagination.scss";
import "swiper/swiper.scss";

import { Button } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import Headline from "../Headline/Headline";
import ServiceTile from "../ServiceTile/ServiceTile";

interface Props {
  images: {
    src: string;
    contentBox?: {
      aboveHeadline?: string;
      headline?: string;
      subline?: string;
      button?: { label: string; link: string };
      content?: string;
    };
    showServiceTile?: boolean;
    tel?: string;
  }[];
}

function ImageSlider(props: Props) {
  const { images } = props;

  if (images.length > 1) SwiperCore.use([Pagination]);
  SwiperCore.use([Autoplay]);

  return (
    <Swiper
      className="ImageSlider"
      onSlideChange={() => {}}
      onSwiper={(swiper) => {}}
      pagination={{ clickable: true }}
      loop={images.length > 1}
      allowTouchMove={images.length > 1}
      autoplay={{ delay: 5000 }}
      key={images.length}
      autoHeight={false}
    >
      {images.map((image, index) => (
        <SwiperSlide
          className="slide"
          style={{ backgroundImage: `url('${image.src}')` }}
          key={index}
        >
          {image.contentBox && (
            <div className="content-box">
              {image.contentBox.aboveHeadline && (
                <Headline type="h4" color="secondary">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: image.contentBox.aboveHeadline,
                    }}
                  ></span>
                </Headline>
              )}

              {image.contentBox.headline && (
                <Headline type="h2">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: image.contentBox.headline,
                    }}
                  ></span>
                </Headline>
              )}

              {image.contentBox.subline && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: image.contentBox.subline,
                  }}
                ></p>
              )}

              {image.contentBox.content && (
                <div
                  dangerouslySetInnerHTML={{
                    __html: image.contentBox.content,
                  }}
                ></div>
              )}

              {image.contentBox.button &&
                (!image.contentBox.button.link.includes("http") ? (
                  <Link to={image.contentBox.button.link}>
                    <Button color="primary" variant="contained">
                      {image.contentBox.button.label}
                    </Button>
                  </Link>
                ) : (
                  <a href={image.contentBox.button.link} target="_blank">
                    <Button color="primary" variant="contained">
                      {image.contentBox.button.label}
                    </Button>
                  </a>
                ))}

              {image.showServiceTile && (
                <ServiceTile tel={image.tel}></ServiceTile>
              )}
            </div>
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

export default ImageSlider;
