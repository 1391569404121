import { API } from '../API';
import { VoucherValue } from '../../interfaces/voucherValue';

export default class VoucherManager {
  static async submitVoucher(
    code: string,
    fkn: string = '',
    comment: string = ''
  ): Promise<VoucherValue> {
    const data: any = {};
    if (fkn) data.fkn = fkn;
    if (comment) data.comment = comment;
    return await API.patch(`vouchers/vouchers/${code}`, data);
  }

  static async getVoucher(
    code: string
  ): Promise<{ code: string | number; message: string }> {
    const data: any = {};
    return await API.get(`vouchers/vouchers/${code}`, data);
  }
}
