import { Grid } from '@material-ui/core';
import React from 'react';

import Box from '../../components/Box/Box';
import Headline from '../../components/Headline/Headline';
import InnerLayout from '../../components/InnerLayout/InnerLayout';
import { Layout } from '../../components/Layout/Layout';
import { Loading } from '../../components/Loading';
import { ContentElements } from '../../interfaces/ContentElements';
import CmsManager from '../../services/manager/CmsManager';
import StateManager from '../../services/manager/StateManager';

interface Props {}

interface State {
  loading: boolean;
  content?: ContentElements;
}

export default class DataProtection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: true,
    };
  }

  async componentDidMount() {
    StateManager.resetProductsFilter();
    StateManager.resetShopFilter();

    const content = await CmsManager.getPageContentsCleaned("datenschutz");

    this.setState({
      content,
      loading: false,
    });
  }

  render() {
    return (
      <Layout>
        <div className="DataProtection">
          {this.state.loading && (
            <InnerLayout>
              <Loading />
            </InnerLayout>
          )}

          {!this.state.loading && (
            <InnerLayout>
              <Box>
                {this.state.content && this.state.content.pageTitle && (
                  <Headline type="h1" color="primary" styling="thin">
                    {this.state.content.pageTitle}
                  </Headline>
                )}

                <Grid container spacing={2}>
                  {this.state.content && (
                    <Grid item xs={12} lg={9}>
                      {Object.keys(this.state.content).map(
                        (item: string, index) => {
                          if (
                            this.state.content &&
                            item !== "pageTitle" &&
                            item !== "pageDescription" &&
                            item.endsWith("Title")
                          ) {
                            return (
                              <div key={index}>
                                <Headline type="h2" color="secondary">
                                  {this.state.content[item]}
                                </Headline>

                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: this.state.content[
                                      item.slice(0, -5)
                                    ] as string,
                                  }}
                                ></p>
                              </div>
                            );
                          } else {
                            return "";
                          }
                        }
                      )}
                    </Grid>
                  )}
                </Grid>
              </Box>
            </InnerLayout>
          )}
        </div>
      </Layout>
    );
  }
}
