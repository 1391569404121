export default class Config {
  static getEnv(name: string) {
    const win = window as any;
    if (win.env && win.env[name] !== undefined) {
      return win.env[name];
    } else {
      return process.env[name];
    }
  }

  static getAPIURL(): string {
    return Config.getEnv("REACT_APP_API_URL");
  }

  static getTravelCategoryId(): any {
    return this.getEnv("REACT_APP_TRAVEL_CATEGORY_ID");
  }

  static getFashionCategoryId(): any {
    return this.getEnv("REACT_APP_FASHION_CATEGORY_ID");
  }

  static getXTenant(): any {
    return this.getEnv("REACT_APP_X_TENANT");
  }

  static getPublicTransportKey(): string {
    return Config.getEnv("REACT_APP_PUBLIC_TRANSPORT_KEY");
  }
}
