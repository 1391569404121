import './DashboardBox.scss';

import { IconName } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';

import Headline from '../Headline/Headline';

interface Props {
  children?: any;
  icon?: any;
  title?: string;
  link?: string;
  placedOnSidebar?: boolean;
}

export default function DasboardBox(props: Props) {
  return (
    <a href={props.link}>
      <div
        className="DashboardBox"
        style={{
          height: props.placedOnSidebar ? "auto" : "100%",
          padding: props.placedOnSidebar
            ? "20px 30px 20px 30px"
            : "40px 30px 40px 30px",
        }}
      >
        {props.title && (
          <div className="DashboardBoxTitle">
            <Headline type="h3" color="white">
              {props.title}
            </Headline>
          </div>
        )}

        {props.children && (
          <div className="DashboardBoxChildren">{props.children}</div>
        )}
        {props.icon && (
          <div className="DashboardBoxIcon">
            <FontAwesomeIcon icon={props.icon as IconName} size="lg" />
          </div>
        )}
      </div>
    </a>
  );
}
